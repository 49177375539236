import { routes } from "../../Routes";
import React, { useMemo, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem("token"));

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  const processedRoutes = useMemo(() => {
    if (routes && routes?.length) {
      return routes?.map((ele, i) =>
        token && ele?.protected ? (
          <Route key={i} path={ele?.path} element={ele?.component} />
        ) : !ele?.protected ? (
          <Route key={i} path={ele?.path} element={ele?.component} />
        ) : null
      );
    }
    return null;
  }, [routes, token]);

  return <Routes>{processedRoutes}</Routes>;
};

export default ProtectedRoutes;
