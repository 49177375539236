import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogout } from "../Redux/Features/analyticsSlice";

const useAutoLogout = (timeout = 600000) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const timerRef = useRef(null);

  // Logout function
  const handleLogout = () => {
    dispatch(
      userLogout({
        url: `${process.env.REACT_APP_URI}reseller/revoke/`,
      })
    );
    localStorage.clear();
    navigate("/login");
  };

  // Reset Timer
  const resetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);

    if (!document.hidden) {
      timerRef.current = setTimeout(() => {
        handleLogout();
      }, timeout);
    }
  };

  useEffect(() => {
    const events = ["mousemove", "keydown", "click", "scroll"];

    // Reset timer on user activity
    const handleActivity = () => resetTimer();

    events.forEach((event) => window.addEventListener(event, handleActivity));
    
    // Handle tab visibility change
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        resetTimer(); // Resume timer when tab is active
      } else if (timerRef.current) {
        clearTimeout(timerRef.current); // Pause timer when tab is inactive
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Start initial timer
    resetTimer();

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [navigate, timeout]);

  return null;
};

export default useAutoLogout;
