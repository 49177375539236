import { Box, Typography } from "@mui/material";
import React from "react";

const SupportAndTickets = () => {
  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography variant="h4" fontWeight={500}>
          This is supports Page
        </Typography>
      </Box>
    </>
  );
};

export default SupportAndTickets;
