import React from "react";
import useGoogleTranslate from "./useGoogleTranslate";

const GoogleTranslate = () => {
  useGoogleTranslate();

  return (
    <div>
      <div id="google_translate_element"></div>
    </div>
  );
};

export default GoogleTranslate;
