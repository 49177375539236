// import BillPaymentPage from "Pages/BillPaymentPage";
// import SimDeleveryPage from "Pages/SimDeleveryPage";
import SupportAndTickets from "Pages/SupportAndTickets";
import { lazy } from "react";

const HomePage = lazy(() => import("Pages/HomePage"));
const PageNotFound = lazy(() => import("Pages/PageNotFound"));
const SimStock = lazy(() => import("Pages/SimStock"));
const OrderPage = lazy(() => import("Pages/OrderPage"));
const LoginPage = lazy(() => import("Pages/LoginPage"));
const StosksPage = lazy(() => import("Pages/StosksPage"));
const CheckoutPage = lazy(() => import("Pages/CheckoutPage"));
const AllAgentsPage = lazy(() => import("Pages/AllAgentsPage"));
const AnalyticsPage = lazy(() => import("Pages/AnalyticsPage"));
const AddPartnerPage = lazy(() => import("Pages/AddPartnerPage"));
const ResetPassLogin = lazy(() => import("Pages/ResetPassLogin"));
const DucumentUpload = lazy(() => import("Pages/DucumentUpload"));
const EsimDetailPage = lazy(() => import("Pages/EsimDetailPage"));
const LoginEmaiVerify = lazy(() => import("Pages/LoginEmaiVerify"));
const DkycPendingPage = lazy(() => import("Pages/DkycPendingPage"));
const AgentDetailPage = lazy(() => import("Pages/AgentDetailPage"));
const TermAndCondition = lazy(() => import("Pages/TermAndCondition"));
const CountryPlansPage = lazy(() => import("Pages/CountryPlansPage"));
const TransactionsPage = lazy(() => import("Pages/TransactionsPage"));
const OrderDetailsPage = lazy(() => import("Pages/OrderDetailsPage"));
const RegionalPlansPage = lazy(() => import("Pages/RegionalPlansPage"));
const PrunePartnerProgram = lazy(() => import("Pages/PrunePartnerProgram"));
const CustomerDetailsPage = lazy(() => import("Pages/CustomerDetailsPage"));
const InternationalSimPage = lazy(() => import("Pages/InternationalSimPage"));
const SalesmanAnalyticsPage = lazy(() => import("Pages/SalesmanAnalyticsPage"));

// Salesman All pages
const SalesmanDashboard = lazy(() => import("Pages/SalesmanDashboard"));

export const routes = [
  {
    path: "*",
    component: <PageNotFound />,
    protected: true,
  },
  {
    path: "/",
    component: <PrunePartnerProgram />,
    protected: true,
  },
  {
    path: "/login",
    component: <LoginPage />,
    protected: true,
  },
  {
    path: "/dashboard",
    component: <HomePage />,
    protected: true,
  },
  {
    path: "/analytics",
    component: <AnalyticsPage />,
    protected: true,
  },
  {
    path: "/international-sim",
    component: <InternationalSimPage />,
    protected: true,
  },
  {
    path: "/d-kyc",
    component: <DkycPendingPage />,
    protected: true,
  },
  {
    path: "/orders",
    component: <OrderPage />,
    protected: true,
  },
  {
    path: "/transaction",
    component: <TransactionsPage />,
    protected: true,
  },
  {
    path: "/order-details/:orderId",
    component: <OrderDetailsPage />,
    protected: true,
  },
  {
    path: "/email_verification",
    component: <LoginEmaiVerify />,
    // protected: true,
  },
  {
    path: "/reset_password",
    component: <ResetPassLogin />,
    protected: true,
  },
  {
    path: "/country-plans/:id",
    component: <CountryPlansPage />,
    protected: true,
  },
  {
    path: "/regional-plans/:regionalName",
    component: <RegionalPlansPage />,
    protected: true,
  },
  {
    path: "/customerDetails/:planId/:countryCode",
    component: <CustomerDetailsPage />,
    protected: true,
  },
  {
    path: "/checkout",
    component: <CheckoutPage />,
    protected: true,
  },
  {
    path: "/international-sim/details/:emailId",
    component: <EsimDetailPage />,
    protected: true,
  },
  {
    path: "/mystock",
    component: <StosksPage />,
    protected: true,
  },
  {
    path: "/document-upload",
    component: <DucumentUpload />,
    protected: true,
  },
  {
    path: "/partner-dashboard",
    component: <SalesmanDashboard />,
    protected: true,
  },
  {
    path: "/all-agents",
    component: <AllAgentsPage />,
    protected: true,
  },
  {
    path: "/agent-details",
    component: <AgentDetailPage />,
    protected: true,
  },
  {
    path: "/partner-analytics",
    component: <SalesmanAnalyticsPage />,
    protected: true,
  },
  {
    path: "/sim-stocks",
    component: <SimStock />,
    protected: true,
  },
  {
    path: "/add-partner",
    component: <AddPartnerPage />,
    protected: true,
  },
  {
    path: "/supports-ticket",
    component: <SupportAndTickets />,
    protected: true,
  },
  {
    path: "/privacy-policy",
    component: <TermAndCondition />,
    protected: true,
  },
  // {
  //   path: "/bill-payments",
  //   component: <BillPaymentPage />,
  //   protected: true,
  // },
  // {
  //   path: "/simdelivery",
  //   component: <SimDeleveryPage />,
  //   protected: true,
  // },
];
