import { useEffect } from "react";

const useGoogleTranslate = (pageLanguage = "en") => {
  // Defaulting to English
  useEffect(() => {
    // Check if the script is already loaded
    const scriptSelector =
      'script[src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]';

    if (!document.querySelector(scriptSelector)) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;

      // Handle script loading errors
      script.onerror = () => {
        console.error("Failed to load Google Translate script.");
      };

      document.body.appendChild(script);

      // Add CSS to hide "Powered by Google" branding and set dropdown height
      const style = document.createElement("style");
      style.innerHTML = `
        .goog-te-gadget img {
          display: none !important;
        }
        .goog-te-gadget {
          font-size: 0 !important;
          line-height: 0 !important;
          // border: 1px solid red !important;
        }
               
        #google_translate_element select {
          border: 1px solid #ccc;
          font-size: 14px;
          border-radius: .5rem;
          padding: 8px 10px;
          margin-top: 44px !important;
        }

        .VIpgJd-ZVi9od-l4eHX-hSRGPd {
          display: none !important;
        }

        .VIpgJd-ZVi9od-ORHb-OEVmcd {
          display: none !important;
        }

        .skiptranslate {
          margin-top: -40px !important;
        }    

        .VIpgJd-ZVi9od-aZ2wEe-OiiCO { //hiding the google translate icon loading
          display: none !important;
          height: 0px !important;
          width: 0px !important;
        }

        .VIpgJd-yAWNEb-hvhgNd-k77Iif-i3jM8c{
          display: none !important;
        }
          .VIpgJd-yAWNEb-hvhgNd-IuizWc {
          display: none !important;
          }
          .VIpgJd-yAWNEb-hvhgNd-N7Eqid{
          display: none !important;
          }
         #goog-gt-tt{
          display: none !important;}

          
      `;
      document.head.appendChild(style);

      // Define the callback function for initializing Google Translate
      window.googleTranslateElementInit = () => {
        try {
          new window.google.translate.TranslateElement(
            { pageLanguage: "en" }, // Set English as the default language
            "google_translate_element"
          );
        } catch (error) {
          console.error("Error initializing Google Translate:", error);
        }
      };
    } else {
      // If the script already exists, initialize it directly
      window.googleTranslateElementInit?.();
    }
  }, [pageLanguage]); // Re-run if the pageLanguage changes
};

export default useGoogleTranslate;
