import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getAnalyticsData = createAsyncThunk(
  "getAnalyticsData",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          // authorization: `Basic ${basicAuth}`,
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const userLogout = createAsyncThunk("userLogout", async ({ url }) => {
  try {
    const response = await axios.get(url, {
      headers: {
        authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        // authorization: `Basic ${basicAuth}`,
        // "Access-Control-Allow-Origin": "*",
      },
    });

    return response?.data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
});

const analyticsSlice = createSlice({
  name: "analyticsSlice",
  initialState: {
    loading: false,
    allAnalytics: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAnalyticsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAnalyticsData.fulfilled, (state, action) => {
        state.loading = false;
        state.allAnalytics = action?.payload;
      })
      .addCase(getAnalyticsData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default analyticsSlice.reducer;
